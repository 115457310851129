import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";
import { Navigation, Pagination, Scrollbar } from "swiper/modules";
import "swiper/css";
import videojs from "video.js";
// import "swiper/css/navigation";
import "swiper/css/pagination";

export default class extends Controller {
  static targets = ["swiper", "loading", "logo", "player"];
  declare readonly swiperTarget: HTMLElement;
  declare readonly loadingTarget: HTMLElement;
  declare readonly logoTarget: HTMLElement;
  declare readonly playerTargets: HTMLElement[];
  players: videojs.Player[];

  connect() {
    this.logoTarget.classList.add("active");
    this.initVideos();
    this.initSwiper();
  }

  initVideos() {
    this.players = this.playerTargets.map((playerTarget) => {
      return videojs(playerTarget, {
        autoplay: false,
        loop: true,
        responsive: true,
        children: ["mediaLoader"],
      });
    });
  }

  initSwiper() {
    new Swiper(this.swiperTarget, {
      modules: [Navigation, Pagination, Scrollbar],
      loop: true,
      slidesPerView: 2,
      spaceBetween: 10,
      centeredSlides: true,
      // allowSlidePrev: false,
      // allowSlideNext: false,
      // allowTouchMove: false,
      autoplay: {
        delay: 1000,
        disableOnInteraction: false,
      },
      on: {
        init: () => {
          this.logoTarget.classList.add("animate-fade-out");
          setTimeout(() => {
            this.loadingTarget.classList.add("animate-fade-out");
          }, 1300);
        },
        slideChange: async (swiper) => {
          this.players.forEach((player) => {
            player.pause();
          });
          const currentPlayer = this.players[swiper.realIndex];
          await currentPlayer.load();
          currentPlayer.play();
        },
      },
    });
  }
}
